import React from "react";
import Layout from "../../components/layout/layout";
import { Link } from "gatsby";

import SEO from "../../components/seo";

const takeingcharge = () => {
  return (
    <>
      <div className="absolute w-full h-32 z-n10 bg-gradient-to-r from-swa-1 to-swa-5"></div>
      <Layout>
        <SEO
          title="SWA Project Taking Charge"
          description="A programme for women from abused families living in shelters with generous support from our donors and supporters "
        />
        <section className="max-w-4xl px-2 mx-auto sm:w-4/5">
          <div className="relative py-16 overflow-hidden bg-white">
            <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
              <div
                className="relative h-full mx-auto text-lg max-w-prose"
                aria-hidden="true"
              >
                <svg
                  className="absolute transform translate-x-32 top-12 left-full"
                  width={404}
                  height={384}
                  fill="none"
                  viewBox="0 0 404 384"
                >
                  <defs>
                    <pattern
                      id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x={0}
                        y={0}
                        width={4}
                        height={4}
                        className="text-gray-200"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    width={404}
                    height={384}
                    fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
                  />
                </svg>
                <svg
                  className="absolute transform -translate-x-32 -translate-y-1/2 top-1/2 right-full"
                  width={404}
                  height={384}
                  fill="none"
                  viewBox="0 0 404 384"
                >
                  <defs>
                    <pattern
                      id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x={0}
                        y={0}
                        width={4}
                        height={4}
                        className="text-gray-200"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    width={404}
                    height={384}
                    fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
                  />
                </svg>
                <svg
                  className="absolute transform translate-x-32 bottom-12 left-full"
                  width={404}
                  height={384}
                  fill="none"
                  viewBox="0 0 404 384"
                >
                  <defs>
                    <pattern
                      id="d3eb07ae-5182-43e6-857d-35c643af9034"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x={0}
                        y={0}
                        width={4}
                        height={4}
                        className="text-gray-200"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    width={404}
                    height={384}
                    fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
                  />
                </svg>
              </div>
            </div>
            <div className="relative px-4 sm:px-6 lg:px-8">
              <div className="mx-auto text-lg max-w-prose">
                <h1>
                  {/* <span className="block text-base font-semibold tracking-wide text-center text-indigo-600 uppercase">
                    Introducing
                  </span> */}
                  <span className="block mt-2 text-3xl font-extrabold leading-8 tracking-tight text-center text-swa-1 sm:text-4xl">
                    SWA Project Taking Charge
                  </span>
                </h1>
                <p className="mt-8 text-xl leading-8 text-gray-500">
                  The Singapore Women's Association (SWA) has been running the
                  "Taking Charge" programme for women from abused families
                  living in shelters with generous support from our donors and
                  supporters since 2008.
                </p>

                <div className="mx-auto mt-6 text-xl leading-8 prose prose-lg text-gray-500 prose-indigo">
                  <p>
                    This is a customized training programme covering the
                    following topics
                  </p>

                  <ul>
                    <li className="list-none">Life Planning</li>
                    <li className="list-none">Communication</li>
                    <li className="list-none">Image Presentation</li>
                    <li className="list-none">Image Styling</li>
                    <li className="list-none">Interviews</li>
                    <li className="list-none">Customer Care Skills</li>
                    <li className="list-none">Financial Literacy</li>
                  </ul>

                  <p>
                    In 2019, three programmes were conducted in Two homes i.e.
                    AFC Temporary Shelter (Singapore Anglican Community
                    Services) and Star Shelter @Singapore Council of Women’s
                    Organisation. About twenty women benefited from the
                    programme. We have received happy and positive feedback from
                    the residents:
                    <br />
                  </p>

                  <p className="p-4 font-light bg-gray-200 rounded-lg">
                    “I like all the courses. It gave me a new perspective in
                    life and to face life’s up and down. I like the image and
                    styling session. I learnt new skills from customer services
                    which was not a common course and was useful for both
                    internal and external customer.”
                  </p>
                  <p className="p-4 font-light bg-gray-200 rounded-lg">
                    “The programme helped me a lot in my life learning. It
                    motivated me to have more energy, more focus. I learnt what
                    to do before attending interview. I learnt how to project
                    myself from the communication session. I like the sharing in
                    the customer services session.”
                  </p>
                  <p className="p-4 font-light bg-gray-200 rounded-lg">
                    “I enjoyed the communication session most. The tips in
                    preparing for interview are useful. “
                  </p>

                  <p>
                    Our programme has helped these traumatized women rebuild
                    their confidence, work on what they want to achieve for
                    themselves and their children, recognize their self-worth,
                    identify their capabilities, knowledge and skills, learn to
                    speak confidently, understand their financial situation and
                    how to plan.
                  </p>
                  <p>
                    It is heartening to see their renewed confidence building up
                    and are able to live life independently with their children
                    when they leave the shelters.
                  </p>
                </div>
                <div className="px-4 py-8 mx-auto my-12 border border-gray-300 rounded-lg max-w-7xl sm:px-6">
                  <h2 className="text-2xl font-bold text-gray-900 sm:text-4xl">
                    <span className="block">
                      Is your organisation looking to support and sponsor our
                      initiatives?
                    </span>
                    <span className="block text-swa-1">Let's discuss.</span>
                  </h2>
                  <div className="flex mt-8">
                    <div className="inline-flex rounded-md shadow">
                      <Link
                        to="/forms/contact"
                        className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-white border border-transparent rounded-md bg-swa-1 hover:bg-swa-3"
                      >
                        Contact us
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default takeingcharge;
